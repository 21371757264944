import { Fieldboard } from './Fieldboard.model';
import { EarnedMedia } from './EarnedMedia.model';
import { NearGoal } from './NearGoal.model';

export class CampaignSeasonData {
  fieldboard: Fieldboard = new Fieldboard();
  keyMoments: Fieldboard = new Fieldboard();
  nearGoal: NearGoal = new NearGoal();
  earnedMedia: EarnedMedia = new EarnedMedia();
}

export class SegmentType {
  id: number = -1;
  campaign_id: number = -1;
  property_id: number = -1;
  property: SegmentProperty = new SegmentProperty();
}

export class SegmentProperty {
  id: number = -1;
  name: string = '';
  created_at: string | null = null;
  updated_at: string | null = null;
  deleted_at: string | null = null;
}

export class CampaignSeason {
  budgets: Array<number> = new Array<number>();
  unitsContracted: number = 0;
  unitsDelivered: number = 0;
  impressionsContracted: number = 0;
  impressionsDelivered: number = 0;
  comments: string = '';
  data: CampaignSeasonData = new CampaignSeasonData();
  published: boolean = false;
  type: SegmentType = new SegmentType();

  calculate() {
    this.calculateUnitsContracted();
    this.calculateUnitsDelivered();
    this.calculateImpressionsContracted();
    this.calculateImpressionsDelivered();
  }

  calculateUnitsContracted() {
    this.unitsContracted =
      this.data.fieldboard.summary.unitsContracted +
      this.data.keyMoments.summary.unitsContracted +
      this.data.nearGoal.summary.unitsReceived;
  }
  calculateUnitsDelivered() {
    this.unitsDelivered =
      this.data.fieldboard.summary.unitsDelivered +
      this.data.keyMoments.summary.unitsDelivered;
  }
  calculateImpressionsContracted() {
    this.impressionsContracted =
      this.data.fieldboard.summary.impressionsContracted +
      this.data.keyMoments.summary.impressionsContracted +
      this.data.nearGoal.summary.impressionsContracted;
  }
  calculateImpressionsDelivered() {
    this.impressionsDelivered =
      this.data.fieldboard.summary.impressionsDelivered +
      this.data.keyMoments.summary.impressionsDelivered +
      this.data.nearGoal.summary.impressionsDelivered +
      this.data.earnedMedia.summary.totalViews;
  }

  setBudgets() {
    this.data.fieldboard.budget = this.budgets[0] || 0;
    this.data.keyMoments.budget = this.budgets[1] || 0;
    this.data.nearGoal.budget = this.budgets[2] || 0;
    this.data.earnedMedia.budget = this.budgets[3] || 0;
  }
}
