export class EarnedMedia {
  id: number = -1;
  budget: number = 0;
  description: string = '';
  summary = {
    totalViews: 0,
  };
  matches: CampaignEarnedMedia[] = [];

  calculateSummary(): void {
    this.summary.totalViews = this.matches.reduce(
      (acc: number, current: CampaignEarnedMedia) =>
        acc + Number(current.views),
      0
    );
  }
}

export class CampaignEarnedMedia {
  video: string = '';
  views: number = 0;
}
